@import "./_variables.scss";

.focus {
  @each $focus-clr in $focus-colors {
    $i: index($focus-colors, $focus-clr);

    @if($i == 1) {
      &-dark {
        @each $offset in $focus-offsets {
          $j: index($focus-offsets, $offset);

          @if($j == 1) {
            &-sml:focus {
              outline: $focus-thickness * 1px solid $focus-clr;
              outline-offset: $offset * 1px;
            }
          }

          @if($j == 2) {
            &-md:focus {
              outline: $focus-thickness * 1px solid $focus-clr;
              outline-offset: $offset * 1px;
            }
          }

          @if($j == 3) {
            &-lrg:focus {
              outline: $focus-thickness * 1px solid $focus-clr;
              outline-offset: $offset * 1px;
            }
          }
        }
      }
    }

    @if($i == 2) {
      &-light {
        @each $offset in $focus-offsets {
          $j: index($focus-offsets, $offset);

          @if($j == 1) {
            &-sml:focus {
              outline: $focus-thickness * 1px solid $focus-clr;
              outline-offset: $offset * 1px;
            }
          }

          @if($j == 2) {
            &-md:focus {
              outline: $focus-thickness * 1px solid $focus-clr;
              outline-offset: $offset * 1px;
            }
          }

          @if($j == 3) {
            &-lrg:focus {
              outline: $focus-thickness * 1px solid $focus-clr;
              outline-offset: $offset * 1px;
            }
          }
        }
      }
    }
  }
}
