/* toastr overrides */
#toast-container {
    //white "x" on hover
  .toast-close-button:hover,
  .toast-close-button:focus {
    color: #fff;
    opacity:1;
  }

//square corners and white shadow
  &.toast-container .ngx-toastr {
    border-radius: 0;
    box-shadow: 0 0 12px rgba(255,255,255,.70);
  }
}