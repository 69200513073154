@import '_variables.scss';
.icon {
  &-green {
    color: $clr-tertiary-3;
  }

  &-primary {
    color: $clr-primary;
  }

  &-secondary {
    color: $clr-secondary;
  }

  &-white {
    color: $clr-white;
  }

  &-calendar-base64 {
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAakAAAGpAHF3nU5AAAA/UlEQVQ4jWP8//8/A1UB1EAQ8V9PT+/n5s2b/8P4+HB6evonEIapAZkDNgvZQAYGhg8MDAwXiDGQgYHhART/l5CQ+A4zEOxlRkbG/4KCgh8o8Dnfu3fvmEEMuIEgTn9/P4OBgQFc1e37kPCVkWRkePIcO9vL0xHM/v//PyOMAffy/v37/yMDXetdYHzq3DucbPQwZMHnDxMDQTDNy8OCk40OULy8f/9+Bm5+fbASDVVehhu3PxNk8/GyongZw4rk3DNgeu5kE6LY6ADDQGK8Oeplyr2MbBAjIzw9Y0/YxCRmZEAwYZPjTZxepgTAvMxEqUFYXUhNQF0XMjAwAAA6Zh2FZ3FzvAAAAABJRU5ErkJggg==');
  }
}

