@import "_variables.scss";

input[type=checkbox] {
  display: inline-flex;
  position: relative;
  align-items: center;
  justify-content: center;
  appearance: none;
  cursor:pointer;

  &:before {
    content: "";
    display: inline-block;
    height: 1rem;
    width: 1rem;
    background-color: $clr-white;
    border:solid 1px $clr-secondary;
    position:relative;
    top:1px;
  }

  &:checked {
    &:after {
      content: "\2714";
      position:absolute;
      left:2px;
      font-size:1rem;
      font-weight:bold;
      color:darken($clr-secondary, 10%);
    }
  }



  &.slide-toggle {
    appearance: none;

    &.focus-light-md:focus {
      outline: solid $focus-thickness * 1px $clr-white;
      outline-offset: $focus-offset * 1px;
    }

    &:focus {
      outline: solid $focus-thickness * 1px $clr-black;
      outline-offset: $focus-offset * 1px;
    }

    &:before {
      content: "";
      display: block;
      height: .7rem;
      border-radius: .35rem;
      width: 2.5rem;
      background-color: lighten($clr-med-gray,10%);
    }

    &:after {
      content: "";
      display: inline-block;
      height: 1.25rem;
      width: 1.25rem;
      border-radius: 50%;
      background-color: lighten($clr-med-gray, 30%);
      position: absolute;
      left: 0;
      transition: left .25s ease-out;
      border: solid 1px $clr-white;
    }

    &:checked {
      &:before {
        background-color: darken($clr-secondary,10%);
      }

      &:after {
        left: 1.25rem;
        background-color: lighten($clr-secondary, 30%);
        border-color: $clr-secondary;
      }
    }
  }
}
