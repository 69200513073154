@import './_variables.scss';

input[type=text] {
  max-width: 100%;
  width: 100%;
  padding: .25rem 0;
  display: block;
  border: solid 1px $input-brdr-clr;
  border-radius: $input-brdr-radius;
  box-sizing:border-box;

  &.block {
    width: 100%
  }
}
