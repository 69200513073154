@import "./_variables.scss";
select {
  max-width: 100%;
  width: 100%;
  display: block;
  padding: .25rem;
  border-color: $input-brdr-clr;
  border-radius: $input-brdr-radius;

  &.block {
    width: 100%
  }

  &:focus {
    outline: solid $focus-thickness * 1px $clr-black;
    outline-offset: $focus-offset * 1px;
  }
}
