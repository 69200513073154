@import './_variables.scss';
@import './icons.scss';



dp-date-picker {
  display: block;
  width: 100%;

  &.half {
    .dp-input-container {
      input[type=text] {
        width: 85%; //room for calendar icon
        max-width: 85%;
        padding: .25rem; //height like other inputs
        border: solid 1px $input-brdr-clr;
        border-radius: $input-brdr-radius;
      }
    }
  }
}

.dp-popup {
  border:none!important;
}

.dp-input-container {
  background-repeat: no-repeat;
  background-position: bottom 3px right 3px;
  @extend .icon-calendar-base64;
  //indent value when selected
  input[type=text] {
    width: 92%; //room for calendar icon
    max-width: 92%;
    padding: .25rem; //height like other inputs
    border: solid 1px $input-brdr-clr;
    border-radius: $input-brdr-radius;

    &:focus {
      outline: solid $focus-thickness * 1px $clr-black;
      outline-offset: $focus-offset * 1px;
    }
  }
}
.dp-picker-input {
  border:none;
}

dp-day-calendar {
  .dp-nav-header-btn {
    padding-left: 0;
  }

  .dp-calendar-nav-container {
    background-color: $clr-secondary;
    border: none !important;
    height: 32px;

    button {
      background-color: transparent;
    }
  }

  .dp-current-location-btn {
    display: none;
  }

  .dp-calendar-wrapper {
    background-color: lighten($clr-med-gray, 20%);
    border-color: $clr-secondary !important;
    border-bottom-right-radius: .25rem;
    border-bottom-left-radius: .25rem;
  }

  .dp-weekdays {
    background-color: $clr-med-gray;
    color: $clr-white;
    margin-bottom: 0 !important;
    display: grid;
    grid-template-columns: repeat(7,1fr);
    height: 24px;
    line-height: 24px;
    vertical-align: middle;
  }

  .dp-calendar-weekday {
    border-left: none !important;
    border-bottom: none !important;
  }

  .dp-calendar-day {
    border-radius: 0;
    border: solid 1px $clr-white;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: $clr-primary;
    transition: background-color .25s ease-out;


    &:hover {
      color: $clr-white;
      background-color: $clr-secondary;
    }

    &:disabled {
      opacity: .25;
      background-color: $clr-med-gray;
      cursor: not-allowed;
      color: $clr-white;

      &:hover {
        opacity: .25;
        background-color: $clr-med-gray;
        cursor: not-allowed;
        color: $clr-white;
      }
    }
  }

  .dp-selected {
    color: $clr-white;
    background-color: $clr-secondary;
  }
}

dp-day-calendar .dp-next-month{
    opacity:.75!important;
}

dp-month-calendar {
  .dp-calendar-month {
    border-radius: 0;
    border: solid 1px $clr-white;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: $clr-primary;
    transition: background-color .25s ease-out;

    &:hover {
      color: $clr-white;
      background-color: $clr-secondary;
    }
  }
}
