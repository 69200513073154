@import './_variables.scss';
textarea {
  resize: none;
  width: 100%;
  max-width: 100%;
  border: solid 1px $input-brdr-clr;
  border-radius: $input-brdr-radius;
  box-sizing: border-box;

  &:focus {
    outline: solid $focus-thickness * 1px $clr-black;
    outline-offset: $focus-offset * 1px;
  }
}
