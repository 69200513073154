




/** PREVIOUSLY ADDED > */

/* You can add global styles to this file, and also import other style files */
.icon-icon-delete-large {
  background-image: url(https://appcenter.jmt.com/assets/images/apps/ob.png);
  width: 0.9375rem;
  height: 1rem;
}

.icon-icon-delete-large {
  background-image: url(https://appcenter.jmt.com/assets/images/apps/crm.png);
  width: 0.9375rem;
  height: 1rem;
}

//@import "~@ng-select/ng-select/themes/default.theme.css";

//ag-Grid
@import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
@import 'ag-grid-community/styles/ag-theme-alpine.css'; // Optional theme CSS

@import "assets/scss/variables";
@import "../node_modules/@jmttg/ag-grid-library/src/lib/ag-grid.scss";

@import "assets/scss/datepicker.scss";
@import "assets/scss/popper.scss";
@import "assets/scss/toastr.scss";


@import 'assets/scss/functions.scss';


//@import 'assets/scss/ag-grid-theme.scss';
@import 'assets/scss/button.scss';
@import 'assets/scss/checkbox.scss';
@import 'assets/scss/cursor.scss';
@import 'assets/scss/dialog.scss';
@import 'assets/scss/focus.scss';
@import 'assets/scss/font.scss';
@import 'assets/scss/headings.scss';
@import 'assets/scss/hr.scss';
@import 'assets/scss/icons.scss';
@import 'assets/scss/kbd.scss';
@import 'assets/scss/label.scss';
@import 'assets/scss/margin.scss';
@import 'assets/scss/ng-select.scss';
@import 'assets/scss/p.scss';
@import 'assets/scss/radio.scss';
@import 'assets/scss/select.scss';
@import 'assets/scss/sr.scss';
@import 'assets/scss/table.scss';
@import 'assets/scss/text-input.scss';
@import 'assets/scss/textarea.scss';
@import 'assets/scss/validation.scss';

@import 'assets/scss/ag-theme-jmt.scss'; 

body {
  margin: 0px;
}

.container {
  position: absolute;
  width: 100%;
}

a,
a:visited,
a:hover {
  color: #1976d2;
  text-decoration: none;
}

a:hover {
  color: #125699;
}

footer {
  margin-top: 8px;
  display: flex;
  align-items: center;
  line-height: 20px;
}

footer a {
  display: flex;
  align-items: center;
}

.toast {
  visibility: visible;
  animation: fadeInAndSlide 0.5s;

  @media (prefers-reduced-motion) {
    animation: fadeIn 0.5s;
  }

  margin: 0 $global-margin ($global-margin+1);
  z-index: 1001;
}

html, body {
  height: 100%;
}


/* < PREVIOUSLY ADDED */


html,
body {
  height: 100%;
  overflow: hidden;
}

body {
  margin: 0;
  font-family: Arial;
}

app-root {
  position: fixed;
  left: 0;
  bottom: 0;
  top: 0;
  transition: right $trns-time $trns-function;
  right: $details-width-closed;
  overflow:visible;//allows menu to fly over project details
}

@media only screen and (min-width: 1024px) {
  app-root {
    &.open {
      right: $details-width-open;
    }
  }
}

section.formField {
  margin: 1rem;
  padding: 1rem;
  color: black;
}

.formLabel {
  align-items:center;
  display: flex;
}

span.error {
  color: var(--mdc-theme-error, #ff5722);
  font-size: var(--mdc-typography-caption-font-size, 12px);
  line-height: var(--mdc-typography-caption-line-height, 20px);
  font-weight: var(--mdc-typography-caption-font-weight, 400);
  letter-spacing: var(--mdc-typography-caption-letter-spacing, normal);
}



span.mat-primary-navyblue-cell {
  color: $clr-primary;
  font-weight: bold;
}

span.flag-red-cell {
  color: #ff0000;
  font-weight: bold;
}

span.flag-yellow-cell {
  color: #A98521;
  font-weight: bold;
}

.project-at-risk-indicator {
  display: inline-flex;
  height: 1.25rem;
  width: 1.25rem;
  border-radius: 50%;
  background-color: #d7962e;
  align-items:center;
  justify-content:center;
  padding-right: 0 !important;
&:before{
      display:block;
      content: "!";
      font-size:1rem;
      font-weight:bold;
  }
}
