@import './_variables.scss';

input[type=radio] {
  appearance: none;
  margin: 0;
  height: 1rem;
  width: 1rem;
  border-radius: 50%;
  border: solid 1px $clr-secondary;
  display: inline-grid;
  place-content: center;
  margin-right:.25rem;
  background-color:$clr-white;

  &:after {
    content: "";
    height: .5rem;
    width: .5rem;
    border-radius: 50%;
    background-color: transparent;
  }

  &:checked {
    &:after {
      background-color: $clr-secondary;
    }
  }
}
