@import "_variables.scss";
label {
  color: $label-clr;

  &.white {
    color: $clr-white;
  }

  &.gray {
    color: $clr-med-gray;
  }

  &.primary {
    color: $clr-primary;
  }

  &.secondary {
    color: $clr-secondary;
  }

  &.block {
    display: block;

    ng-select, select, textarea, dp-date-picker {
        margin-top:.25rem; //room for focus outline
    }
  }

  &.bold {
    font-weight: bold;
  }

  &.inln-flx-center {
    display: inline-flex;
    align-items: center;
  }
}
