@import "_variables.scss";

kbd {
  background-color: $clr-med-gray;
  border-radius: 0.2rem;
  box-shadow: inset 0 -0.1rem 0 rgba(0,0,0,.25);
  color: #fff;
  font-size: 87.5%;
  padding: 0.2rem 0.4rem;
}
