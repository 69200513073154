@import "~@ng-select/ng-select/themes/default.theme.css";
@import "./_variables.scss";

/* THEME OVERRIDES > */
.ng-select {
  //focus - light border
  &.focus-light-md.ng-select-focused:not(.ng-select-opened) > .ng-select-container {
    outline: solid $focus-thickness * 1px $clr-white;
    outline-offset: $focus-offset * 1px;
    box-shadow: none;
    border: none;
  }
  //focus - dark border
  &.ng-select-focused:not(.ng-select-opened) > .ng-select-container {
    outline: solid $focus-thickness * 1px $clr-black;
    outline-offset: $focus-offset * 1px;
    box-shadow: none;
    border: 1px solid $input-brdr-clr;
  }


  &.ng-select-single {
    .ng-select-container {
      height: 28px;
    }
  }

  &.ng-select-multiple {
    .ng-select-container {
      .ng-value {
        max-width: 325px;
        overflow: hidden;
      }
    }
  }

  .ng-select-container {
    color: #333;
    background-color: #fff;
    border-radius: $input-brdr-radius;
    border: 1px solid $input-brdr-clr;
    min-height: 0;
    align-items: center;

    .ng-value-container .ng-input > input {
      &:focus {
      }
    }
  }

  .ng-clear-wrapper {
    color: #000;
    top: 1px; //align with down arrow
  }

  .ng-arrow-wrapper {
    .ng-arrow {
      border-color: #000 transparent transparent;
    }
  }
}
.ng-dropdown-panel {
  .ng-dropdown-panel-items {
    .ng-option {
        white-space:normal!important; //override default setting of nowrap
      &.ng-option-marked{
          background-color: lighten($clr-secondary, 40%);
      }
    }
  }
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-input {
  padding: 0 0 6px 3px; /* theme override */
}

