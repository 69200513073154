/*------------------------------------*\
    $VARIABLES
\*------------------------------------*/

//Colors
$gray : #808080;
$gray-light : #f1f1f1;
$gray-light-2 : #e2e2e2;
$gray-light-3 : #ddd;
$gray-light-4 : #b2b2b2;
$gray-light-5 : #858585;
$gray-med : #6d6d6d;
$gray-dark : #3d3d3d;
$gray-dark-2 : #131313;
$white : #fff;
$black : #000;
$header-blue:#98beed;
$dark-blue:#164883;
$link-blue:#1872e2;
$med-blue-2:#417bad;
$med-blue:#8ab1d3;
$pale-blue:#9fc5f8;
$light-blue:#d8e5f0;
$light-green:#d3e1b5;
$med-green:#46aa2a;
$date-green:#349c42;
$dark-green:#33641e;
$light-red:#f59999;
$dark-red:#ae0000;
$med-orange:#e68b23;
$light-orange:#f8e3cb;
$angular-tree-opened-gray:#a5b1bb;

//new
$clr-primary: #002B54;
$clr-secondary: #0096D6;
$clr-tertiary-1: #13161C;
$clr-tertiary-2: #9F9F9F;
$clr-tertiary-3: #8AC640;
$clr-tertiary-4: #C7C8CA;
$clr-bg: #E6E6E6;
$clr-med-gray: #707070;
$clr-white: #fff;
$clr-black: #000;


$dim : rgba(0,0,0,0.5);
$dimmer : rgba(0,0,0,0.8);

$error : #f00;
$valid : #089e00;

$brand-facebook: #03539e;
$brand-twitter: #35ccff;
$brand-linkedin: #217bc1;

//Typography

$font : "Helvetica Neue", "HelveticaNeue", "Helvetica", "Arial", sans-serif;
$font-secondary : Georgia, Times, "Times New Roman", serif;

$font-size-h1:24px;
$font-size-norm : 1em;
$font-size-slighty-larger-2: 1.25em;
$font-size-slightly-larger : 1.0625em;
$font-size-slightly-smaller : 0.875em;
$font-size-slightly-smaller-2:.825em;
$font-size-small : 0.75em;
$font-size-small-2:0.65em;
$font-size-large : 1.4375em;

//Layout
$max-width: 95%;
$offset-left-1: 4.5rem;

$l-sidebar-width: 10rem;

$pad : 1rem;
$nav-height:38px;
$header-height: 77px;
$header-nav-height: calc($header-height + $nav-height);
$main-padding: $pad;

//Defaults
$space : 1rem;
$space-and-half : calc($space*1.5);
$space-double: calc($space*2);
$space-triple: calc($space*3);
$space-quad: calc($space*4);
$space-half: calc($space/2);
$space-quarter: calc($space/4);
$pad : 1rem;
$pad-and-half: calc($pad*1.5);
$pad-double: calc($pad*2);
$pad-half: calc($pad/2);
$pad-quarter: calc($pad/4);
$pad-three-quarter: calc($pad * .75);

//Borders
$border-med: 3px;
$border-thick: 7px;


//Breakpoints
$bp-small : 24em;
$bp-small-2 : 29.75em;
$bp-small-3 : 39.8em;
$bp-med : 46.8em;
$bp-med-2 : 48em;
$bp-large : 50em;
$bp-large-2 : 66em;
$bp-xl : 73em;
$bp-xxl : 89em;
$bp-xxxl : 93em;

//z-index
$tool-tip-z-index:99;
$tool-tip-span-z-index:100;
$tool-tip-close-z-index:101;
$menu-z-index:3; //above project details


$global-margin: 1rem;
$global-padding: 1rem;

$primary-color: #164883;
$secondary-color: #767676;

$nav-height:38px;
$footer-height:40px;
$header-height: 80px;
$tablet-menu-switch-bp:720px;
$phone-menu-switch-bp:500px;

$trns-time: .3s;
$trns-function: ease-in-out;

$details-width-closed: 20px;
$details-width-open: 500px;


/* MARGINS/PADDING */
//margin/padding values (dash is equal to decimal point. decimal point is not allowed in class name)
$mrgn-pd-values: ( "0", "-25", "-50", "-75", "1", "1-25", "1-5", "1-75", "2", "2-5", "3" );

//do not edit trbl - top, right, bottom, left prefixes
$mrgn-pd-trbl: ( 't', 'r', 'b', 'l' );

//input
$input-brdr-clr: lighten($clr-black,30%);
$input-brdr-radius:3px;
$label-clr: lighten($clr-black,10%);

/* FOCUS */
$focus-thickness: 2;
$focus-offset: 4;
//dark & light focus colors
$focus-colors: ( $clr-black, $clr-white );
//focus offset sml, md, lrg (pixels)
$focus-offsets: ( 2, 4, 8 );
