@import "_variables.scss";

.ag-theme-jmt {
  --ag-foreground-color: $clr-black; //cell text color
  --ag-header-background-color: #164883; //matches bg in library, can't override
  --ag-odd-row-background-color: #f0f0f0; //odd row bg
  --ag-borders: none; //extraneous borders?
  --ag-borders-critical: none; //extraneous borders?
  --ag-header-column-resize-handle-width: 1px; //vert separator width
  --ag-header-column-resize-handle-color: #99cbe0; //vert sep color

  .ag {
    &-root {
      border: none; //extraneous border
      &-wrapper {
        border: none; //extraneous border
      }
    }

    &-header {
      border: none; //extraneous border
      &-cell {
        //allow header text to wrap
        &.wrap-header-text {
          white-space: normal;
        }
        &-text {
          font-weight: normal; //header font
        }
      }

      &-active:after {
        border: solid 2px #fff !important; //header focus on keyboard
      }
    }

    &-icon-menu:before {
      color: #99cbe0; //filter menu
    }

    &-row {
      &:hover {
        background-color: #e4f0fc; //row hover
      }

      &-selected {
        background-color: #e4f0fc; //selected bg
        border: solid 1px #002B54; //selected border
      }
    }

    &-ltr {
      .ag-cell {
        //remove selected cell border color
        &-focus:not(.ag-cell-range-selected):focus-within {
          border: none;
        }
      }
    }
  }
}




