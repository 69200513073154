@import "_variables.scss";

button {
  cursor: pointer;
  padding: 0.5rem 1rem;
  border-radius: 0.3rem;
  border: none;
  color: $clr-white;

  &.focus-light-md:focus {
    outline: solid $focus-thickness * 1px $clr-white;
    outline-offset: $focus-offset * 1px;
  }

  &:focus {
    outline: solid $focus-thickness * 1px $clr-black;
    outline-offset: $focus-offset * 1px;
  }

  &.no-padding {
    padding: 0;
  }

  &.no-border {
    border: none;
  }

  &.no-bg {
    background-color: transparent;
  }

  &.icon {
    @extend .no-padding;
    @extend .no-border;
    @extend .no-bg;
  }

  &.link {
    @extend .icon;
    text-decoration: underline;
    color: $clr-secondary;

    &.on-dark {
      color: darken($clr-secondary, 20%);
    }
  }

  &.primary {
    background-color: $clr-primary;
  }

  &.secondary {
    background-color: $clr-secondary;
  }

  &.gray {
    background-color: $clr-med-gray;

    &-dark {
      background-color: $gray-dark;
    }
  }

  &.small {
    padding: .25rem .5rem;
  }

  &.pill {
    padding: 0 .5rem;
    border-radius: .5rem;
    &.padded{
        padding: .25rem .5rem;
        border-radius:1rem;
    }

    &.outlined {
      background-color: transparent;

      &.primary {
        border: solid 2px $clr-primary;
        color: $clr-primary;
      }

      &.secondary {
        border: solid 2px $clr-secondary;
        color: lighten($clr-secondary, 20%);
      }

      &.gray {
        border: solid 2px $clr-med-gray;
        color: $clr-med-gray;

        &-dark {
          border: solid 2px $gray-dark;
          color: $gray-dark;
        }
      }
    }
  }

  &:disabled {
    opacity: .5;
    cursor: not-allowed;
  }

  &.flex-centered {
    display: flex;
    align-items: center;
  }
}

.btn-container {
  &.txt-right {
    text-align: right;
  }

  &.txt-center {
    text-align: center;
  }

  button {
    margin-left: .5rem;

    &:first-of-type {
      margin-left: 0;
    }
  }
}


/*
button {
  -webkit-appearance: none;
  appearance: none;
  border: 1px solid transparent;
  background: 0 0;
  display: inline-block;
  vertical-align: middle;
  
  cursor: pointer;
  text-align: center;
  line-height: 1;
  transition: all .25s ease-out, color .25s ease-out;
  -webkit-transition: all .25s ease-out, color .25s ease-out;
  background-color: #0096d6;
  color: #fdfdfd;
  border-radius: 0.3rem;
  text-transform: uppercase;
}
*/
