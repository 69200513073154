@import '_variables.scss';

@mixin setHColorVariants() {
  color: darken($clr-med-gray, 10%);

  &.primary {
    color: $clr-primary;
  }

  &.secondary {
    color: $clr-secondary;
  }

  &.tertiary-1 {
    color: $clr-tertiary-1;
  }

  &.tertiary-2 {
    color: $clr-tertiary-2;
  }

  &.tertiary-3 {
    color: $clr-tertiary-3;
  }

  &.tertiary-4 {
    color: $clr-tertiary-4;
  }

  &.white {
    color: $clr-white;
  }

  &.black {
    color: $clr-black;
  }

  &.light-gray {
    color: lighten($clr-tertiary-2, 10%);
    &.weight-normal{
        font-weight:normal;
    }
  }
}

h1, .h1-size {
	font-size: 30px;
	@include setHColorVariants();
}
		
h2, .h2-size {
	font-size: 26px;
	@include setHColorVariants();
}
		
h3, .h3-size {
	font-size: 22px;
	@include setHColorVariants();
}
		
h4, .h4-size {
	font-size: 1rem;
	@include setHColorVariants();
}
		
h5, .h5-size {
	font-size: .9rem;
	@include setHColorVariants();
}

.h14-size{
    font-size:14px;
}




