@import '_variables.scss';

hr{
	width:100%;
	height:0px;
	border:none;
	&.secondary{
		border-bottom: solid 1px $clr-secondary;
	}
	&.tertiary-2{
		border-bottom: solid 1px $clr-tertiary-2;
	}
}
