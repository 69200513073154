@import '_variables.scss';

table {
  border-spacing: 0;

  thead {
    tr {
      th {
        font-weight: normal;
        text-align: right;
        color: lighten($clr-tertiary-2, 10%);
        letter-spacing: .5px;
      }
    }
  }

  tbody {
    tr {

      td {
        text-align: right;
        padding: .15rem; 
        color: $clr-white;
        letter-spacing: .5px;

        &.false-header {
          font-weight: normal;
          color: lighten($clr-tertiary-2, 10%);
          line-height: 1rem;
          vertical-align: baseline; //align header wraps
        }

        &.divider {
          border-bottom: solid 1px darken($clr-secondary,15%);
        }

        &.text-left {
          text-align: left;
        }

        &.projectFinancials-typeNames {
          text-align: left;
        }

        &.tableSectionHeader {
          &-main {
            text-align: left;
            font-weight: normal;
            padding: .25rem .25rem .25rem 0;
            color: lighten($clr-tertiary-2, 10%);
            //border-top: solid 1px darken($clr-secondary,10%);
            margin-top: .5rem;

            .sub-header {
              font-weight: normal;
              font-size: .75rem;
              font-style: italic;
              color: lighten($clr-tertiary-2, 10%);
              margin-top: .25rem;
            }
          }

          &-report {
            padding: .25rem .25rem .25rem 0;
            text-align: right;
            //border-top: solid 1px darken($clr-secondary,10%);
            margin-top: .5rem;
          }
        }
      }
    }
  }
}
